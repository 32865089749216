// Core Packages
import classnames from 'classnames';
import { useRouter } from 'next/router';

// Assets
import { CaretIcon, CrossIcon } from '@/assets/icons/shared';

// Styles
import styles from './style.module.css';

export default function BackButton({
  id,
  path,
  className,
  onClick,
  showCross,
}) {
  const router = useRouter();
  const containerClasses = classnames(styles.backButton, className);

  const handleBackClick = () => {
    if (onClick) {
      return onClick();
    }
    if (!path) {
      router.back();
    } else {
      router.replace(path);
    }
    return true;
  };

  return (
    <div
      className={containerClasses}
      onClick={handleBackClick}
      onKeyDown={handleBackClick}
      tabIndex={0}
      role="button"
    >
      {showCross ? (
        <CrossIcon className={styles.buttonSize} id={id} />
      ) : (
        <CaretIcon className={styles.buttonSize} id={id} />
      )}
    </div>
  );
}
