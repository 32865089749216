// Core Packages
import classNames from 'classnames';

// Material
import { Fade, Modal, Backdrop } from '@material-ui/core';

// Utils
import { CancelCross } from '@/assets/icons/shared';

// Styles
import styles from './style.module.css';

export default function StyledModal({
  modalClass,
  paperClass,
  onClose,
  open,
  hideCross = false,
  children,
}) {
  const modalClasses = classNames('row a-center j-center', modalClass);
  const paperClasses = classNames(styles.paper, paperClass);
  return (
    <Modal
      className={modalClasses}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1500,
      }}
    >
      <Fade in={open}>
        <div className="col a-center j-center">
          <div className={paperClasses}>{children}</div>
          {!hideCross && (
            <div
              onKeyDown={onClose}
              onClick={onClose}
              role="button"
              tabIndex={0}
              className={styles.crossButton}
            >
              <CancelCross />
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
