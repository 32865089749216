// Core Packages
import classnames from 'classnames';

// Material
import BackButton from '@/components/BackButtonNew';
import BackButtonAction from '@/components/BackButtonAction';

// Styles
import styles from './style.module.css';

export default function Header({
  className,
  showBackButton,
  showBackButtonAction,
  title,
  action,
  onClick,
  id,
}) {
  const containerClasses = classnames(
    'row a-center w-100 flex-1 overflow-hidden',
    styles.header,
    className,
  );
  return (
    <div className={containerClasses} id={id}>
      {showBackButton && <BackButton className={styles.backButton} />}
      {showBackButtonAction && (
        <BackButtonAction className={styles.backButton} onClick={onClick} />
      )}
      {title && <h1 className={styles.title}>{title}</h1>}
      {action}
    </div>
  );
}
