// Core Packages
import classnames from 'classnames';

// Assets
import { CaretIcon } from '@/assets/icons/shared';

// Styles
import styles from './style.module.css';

export default function BackButtonAction({ className, onClick }) {
  const containerClasses = classnames(styles.backButton, className);

  return (
    <div
      className={containerClasses}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
    >
      <CaretIcon className={styles.buttonSize} />
    </div>
  );
}
